import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });


const theme =  createTheme({      
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3'),
    primaryAction:createColor('#2d3748'),
  },
  customBorder: {
    border: `3px solid grey`
  },
});

export default theme;