const ProjectCard = (props) => {
  console.log("props", props);

  const classCreator = (cssStr, propImgClass) => {
    return cssStr + " " + propImgClass + "-background-image";
  };

  let classMasterStr =
    "rad-8-all d-flex d-flex-dir-column p-1 d-flex-justify-end font-type-inter box-shadow-5 card width-49-5-1200px ";
  return (
    <div
      style={{ backgroundColor: "orange", height: "50rem" }}
      className={classCreator(classMasterStr, props.projectObj.img_class)}
      onClick={() => {
        console.log("clllllll");

        props.onClick();
      }}
    >
      <div
        style={{ height: "30%" }}
        className="w-100 rad-8-all p-2 d-flex d-flex-dir-column gap-1 w-bg primary-action-color box-shadow-5"
      >
        <div className="font-16 weight-600">
          {" "}
          {props?.projectObj?.project_name}
        </div>

        <div className="font-14 ">
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          sagittis urna lacus, nec imperdiet erat sodales a. Suspendisse
          condimentum suscipit eros ut malesuada */}

          {props?.projectObj?.project_details}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
