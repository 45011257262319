import LogoV1 from "../assets/logo_v1.svg";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { HashLink } from "react-router-hash-link";
const NavbarGlobal = (props) => {
  return (
    <div className="d-flex d-flex-justify-centre p-top-1">
      {/* width-70 */}
      <div className="d-flex width-70  rad-8-all font-type-inter p-1 w-bg width-70-600px">
        <div className="d-flex width-100 d-flex-justify-space-between">
          <div className="weight-700 font-20 d-flex d-flex-align-centre gap-0-5 display-hide-600">
            <img src={LogoV1} alt="Roshfolio Logo" style={{ height: "4rem" }} />
            <div>
              <span className="header-ascent"> Rosh</span>
              <span className=" primary-action-color">Folio</span>
            </div>
          </div>

          <div className="d-flex gap-3 font-14 weight-600 d-flex-align-centre display-hide-600">
            {/* <div>Home</div> */}

            <HashLink
              smooth
              to="#projects-list"
              className="t-dec-none primary-action-color"
            >
              <div>Projects</div>
            </HashLink>

            <HashLink
              smooth
              to="#contact-me"
              className="t-dec-none primary-action-color"
            >
              <div>Contact</div>
            </HashLink>
            {/* <div>Contact</div> */}
          </div>

          <div className="display-show-600 flex-grow d-flex-justify-space-between">
            <div>
              <img
                src={LogoV1}
                alt="Roshfolio Logo"
                style={{ height: "4rem" }}
              />
            </div>
            <div>
              <HashLink
                smooth
                to="#projects-list"
                className="t-dec-none primary-action-color"
              >
                <GridViewIcon
                  sx={{ fontSize: "4rem" }}
                  style={{ color: "#2d3748" }}
                />
              </HashLink>
            </div>
            <div>
              <HashLink
                smooth
                to="#contact-me"
                className="t-dec-none primary-action-color"
              >
                <AccountBoxIcon
                  sx={{ fontSize: "4rem" }}
                  style={{ color: "#2d3748" }}
                />
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarGlobal;
