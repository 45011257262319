const ProjectsArr = [
  {
    project_name: "Team Management UI",
    project_details: "A full stack project",
    img_class: "test-ui",
    project_link: "https://fe-test-e.vercel.app/",
    tech_stack: "Typescript | Node.js | Express.js | React.js | Postgres",
  },

  {
    project_name: "Teesta-site",
    project_details: "A full stack project",
    img_class: "teesta",
    project_link: "https://teesta.co/",
    tech_stack: "Node.js | Express.js | React.js",
  },

  {
    project_name: "Wellness app ui",
    project_details: "Designed on Figma",
    img_class: "startup",
    project_link:
      "https://www.figma.com/proto/jVks1yvFKBxByOU1dhrD70/1st-iteration?page-id=0%3A1&type=design&node-id=8-269&viewport=317%2C-96%2C0.4&t=vGaV1s0dAK0waJUX-1&scaling=scale-down&starting-point-node-id=8%3A269&mode=design",
    tech_stack: "Figma",
  },

  {
    project_name: "Pranic Healing Site",
    project_details: "A full stack project",
    img_class: "gmcks",
    project_link: "http://43.205.84.64:3000/",
    tech_stack: "Node.js | Express.js | React.js",
  },

  {
    project_name: "Tic-tac-toe",
    project_details: "Simple game",
    img_class: "tic-tac-toe",
    project_link: "https://clever-queijadas-b602f3.netlify.app",
    tech_stack: "React.js",
  },

  {
    project_name: "Ecommerce app",
    project_details: "Clone application",
    img_class: "Ecommerce",
    project_link: "https://ecomm-watch.netlify.app",
    tech_stack: "Node.js | Express.js | React.js",
  },
];
export default ProjectsArr;
