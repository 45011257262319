import "./App.css";
import NavbarGlobal from "./components/navbarGlobal";
import Button from "@mui/material/Button";
import ProjectsArr from "./configs/projects_details";
import Footer from "./components/footer";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ProjectCard from "./components/projectCard";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function App() {
  const [open, setOpen] = React.useState(false);
  const [dialogueDataObj, setDialogueDataObj] = React.useState({});

  const handleClickOpen = (data) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Title = ({ children }) => (
    <div className="font-16 weight-600">{children}</div>
  );

  const Content = ({ children }) => (
    <div className="font-14 primary-action-color">{children}</div>
  );

  const ContentBold = ({ children }) => (
    <div className="font-14 weight-600 primary-action-color">{children}</div>
  );

  const DialogueReturner = () => {
    console.log("dialogueDataObj", dialogueDataObj);
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex d-flex-justify-space-between d-flex-align-centre">
            <Title>{dialogueDataObj.project_name}</Title>
            <CloseIcon onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Content>{dialogueDataObj.project_details}</Content>
          </DialogContentText>
          <div className="col-fff">------</div>
          <DialogContentText id="alert-dialog-description">
            <div className="d-flex gap-1">
              <ContentBold>Link:</ContentBold>

              <Content>
                <Link to={dialogueDataObj.project_link} target="_blank">
                  {dialogueDataObj.project_link}
                </Link>
              </Content>
            </div>
          </DialogContentText>

          <DialogContentText id="alert-dialog-description">
            <div className="d-flex gap-1">
              <ContentBold>Tech stack:</ContentBold>

              <Content> {dialogueDataObj.tech_stack}</Content>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="App d-flex d-flex-dir-column master-background">
      <DialogueReturner open={open} onClose={handleClose} />

      {/* navbar */}
      <NavbarGlobal />
      {/* intro header */}
      <div className="d-flex d-flex-justify-centre p-top-1">
        <div
          className="width-70-600px d-flex gap-1 d-flex-justify-space-between "
          style={{ height: "40rem" }}
        >
          {/* width-70 */}
          <div className="p-4 font-type-inter  d-flex d-flex-dir-column d-flex-justify-space-between rad-8-all w-bg intro-width-media gap-1">
            <div className="d-flex d-flex-dir-column gap-3">
              <div className="font-40-600px  weight-700 primary-action-color ">
                Hi there...
              </div>
              <div className="font-16-600px weight-600 primary-action-color">
                I am Roshan Nair. I like to build things. Previously I built
                products, now I build software. In the future, I may build God.
              </div>
            </div>

            <div className="d-flex d-flex-justify-space-between align-600 ">
              <div className="d-flex gap-1 align-600">
                <Button
                  variant="contained"
                  color="primaryAction"
                  style={{ fontSize: "1rem" }}
                  href="https://drive.google.com/file/d/1csd2c_2K_Ot3NACfiTL_T4pL0pxBd-1N/view?usp=sharing"
                  target="_blank"
                >
                  View my Resume
                </Button>
                <Button
                  variant="outlined"
                  style={{ fontSize: "1rem" }}
                  href="mailto:roshannair24@gmail.com"
                  color="primaryAction"
                >
                  Contact me
                </Button>
              </div>
              <div className="col-fff">-</div>
              <div className="d-flex gap-1 d-flex-justify-space-between">
                <Link
                  to={
                    "https://www.linkedin.com/in/roshan-nair-15a93810a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2BSjH2ixyTcqcCzqzbLA8iQ%3D%3D"
                  }
                  target="_blank"
                >
                  <LinkedInIcon
                    sx={{ fontSize: "3rem" }}
                    style={{ color: "#2d3748" }}
                  />
                </Link>

                <Link
                  to={"https://www.instagram.com/freedom24car/"}
                  target="_blank"
                >
                  <InstagramIcon
                    sx={{ fontSize: "3rem" }}
                    style={{ color: "#2d3748" }}
                  />
                </Link>

                <Link to={"https://twitter.com/freedom24994"} target="_blank">
                  <TwitterIcon
                    sx={{ fontSize: "3rem" }}
                    style={{ color: "#2d3748" }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="width-30 me-backgrund-image rad-8-all invsible-below-900px">
            tghythj
          </div>
        </div>
      </div>

      {/* project section */}

      <div className="d-flex d-flex-justify-centre p-top-1">
        <div
          className="d-flex  width-70-600px gap-1 flex-wrap-900"
          id="projects-list"
        >
          {ProjectsArr.map((item, index) => {
            return (
              <ProjectCard
                projectObj={item}
                key={index}
                refIndex={index}
                onClick={() => {
                  setDialogueDataObj(item);

                  handleClickOpen(item);
                }}
              />
            );
          })}
        </div>
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
}

export default App;
