import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="d-flex d-flex-justify-centre p-top-1 p-bot-1" id="contact-me">
      <div
        className="d-flex  width-70-600px gap-1 p-4  rad-8-all  font-type-inter w-bg "
        style={{ height: "30rem" }}
      >
        {/* d-flex */}
        <div className="width-40 d-flex d-flex-dir-column d-flex-justify-space-between display-hide-600" >
          <div className="d-flex d-flex-dir-column gap-0-5  primary-action-color">
            <div className="font-40 weight-700">Contact me.</div>
            <div className="font-16 weight-600">
              I am here. I am there. I am everywhere.
            </div>
          </div>

          <div className="d-flex gap-1  primary-action-color">
            <Link to={"https://github.com/Roshannair24"} target="_blank">
              <GitHubIcon
                sx={{ fontSize: "3rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link
              to={
                "https://www.linkedin.com/in/roshan-nair-15a93810a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2BSjH2ixyTcqcCzqzbLA8iQ%3D%3D"
              }
              target="_blank"
            >
              <LinkedInIcon
                sx={{ fontSize: "3rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link
              to={"https://www.instagram.com/freedom24car/"}
              target="_blank"
            >
              <InstagramIcon
                sx={{ fontSize: "3rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link to={"https://twitter.com/freedom24994"} target="_blank">
              <TwitterIcon
                sx={{ fontSize: "3rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>
          </div>
        </div>
        {/* d-flex */}
        <div className="width-60  d-flex-dir-column display-hide-600">
          <div style={{ height: "20%" }}></div>
          <div
            style={{ height: "80%" }}
            className=" d-flex d-flex-dir-column  d-flex-justify-space-between"
          >
            <div className="d-flex d-flex-dir-column gap-0-5">
              <div className="font-16 weight-600">roshannair24@gmail.com</div>
              <a href="https://drive.google.com/file/d/1csd2c_2K_Ot3NACfiTL_T4pL0pxBd-1N/view?usp=sharing" target="_blank" rel="noreferrer">
                My Resume
              </a>
            </div>

            <div className="d-flex d-flex-dir-column gap-0-5">
              <div>
                This website is designed, developed, deployed and maintained by
                Roshan Nair.
              </div>
              <div>Copyright 2023. All rights reserved.</div>
            </div>
          </div>
        </div>
        <div className="display-show-600 d-flex-dir-column gap-1">
          <div className="font-20 weight-700">Contact me.</div>

          <div className="font-12 weight-600">
            I am here. I am there. I am everywhere.
          </div>
          <div className="col-fff">---</div>

          <div className="d-flex gap-1  primary-action-color d-flex-justify-space-between ">
            <Link to={"https://github.com/Roshannair24"} target="_blank">
              <GitHubIcon
                sx={{ fontSize: "2rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link
              to={
                "https://www.linkedin.com/in/roshan-nair-15a93810a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2BSjH2ixyTcqcCzqzbLA8iQ%3D%3D"
              }
              target="_blank"
            >
              <LinkedInIcon
                sx={{ fontSize: "2rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link
              to={"https://www.instagram.com/freedom24car/"}
              target="_blank"
            >
              <InstagramIcon
                sx={{ fontSize: "2rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>

            <Link to={"https://twitter.com/freedom24994"} target="_blank">
              <TwitterIcon
                sx={{ fontSize: "2rem" }}
                style={{ color: "#2d3748" }}
              />
            </Link>
          </div>

          <div className="d-flex d-flex-dir-column gap-0-5">
            <div className="font-12 weight-600">roshannair24@gmail.com</div>
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              My Resume
            </a>
          </div>

          <div className="col-fff">---</div>

          <div className="d-flex d-flex-dir-column gap-0-5">
            <div>
              This website is designed, developed, deployed and maintained by
              Roshan Nair.
            </div>
            <div>Copyright 2023. All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
